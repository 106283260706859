@font-face {
  font-family: "Webly Sleek UI";
  font-weight: 300;
  src: url("../assets/fonts/WeblySleekUILight.woff2") format("woff2");
}

@font-face {
  font-family: "Webly Sleek UI";
  font-weight: normal;
  src: url("../assets/fonts/WeblySleekUILight.woff2") format("woff2");
}

@font-face {
  font-family: "Webly Sleek UI";
  font-weight: bold;
  src: url("../assets/fonts/WeblySleekUISemibold.woff2") format("woff2");
}
