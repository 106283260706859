@import './fonts';
@import './custom';
@import 'bootstrap';

.result {
  @media print {
    max-width: none;

    .fs-5 {
      font-size: 18px !important;
    }
  }
}

.dropdown-menu-end {
  display: block;
  right: 0;
}

.navbar-main {
  height: 78px;
}

.result-details {
  small {
    display: block;
    font-size: 14px;
    color: var(--bs-gray-600);
  }
}

.list-results {
  --border: 1px solid var(--bs-gray-400);

  .list-results-header {
    border-right: var(--border);
    border-left: var(--border);

    > div {
      border-top: var(--border);
      border-bottom: var(--border);
      padding-top: 10px;
      padding-bottom: 10px;
    }

    > div + div {
      border-left: var(--border);
    }
  }

  .list-results-row {
    border-bottom: var(--border);
  }

  > li > div {
    display: flex;
    align-items: center;
  }

  .list-results__name {
    @include media-breakpoint-only(xl) {
      width: 270px;
    }

    @include media-breakpoint-up(xxl) {
      width: 290px;
    }
  }

  .list-results__birthday {
    @include media-breakpoint-only(xl) {
      width: 120px;
    }

    @include media-breakpoint-up(xxl) {
      width: 180px;
    }
  }

  .btn-group > .btn {
    width: 32px;
    height: 30px;
    line-height: 1;
  }
}
